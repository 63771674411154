<template>
    <div class="contract-create-page">
        <div class="contract-create-title">
            <a-row>
                <a-col :span="19">
                </a-col>
                <a-col :span="5" style="display: flex;justify-content: space-around">
                    <a-button type="primary">打印</a-button>
                    <a-button type="primary">合同确认</a-button>
                    <a-button>合同重置</a-button>
                </a-col>
            </a-row>
        </div>
        <div style="height: 1px">



        </div>

    </div>
</template>
<script>
export default {
    name: 'ContractDetail',
    data() {
        return {
            showChangeGuardian:false,
            showChangeAddr:false,


        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        plainTypeChange(e){
            console.log(e.target.value)
        },
        changeGuardianSubmit() {
            this.commissionInfoVisible = true;
        }
    }
}
</script>
<style scoped lang="less">
.contract-create-page {
    box-sizing: border-box;
    margin-bottom: 120px;

    .contract-create-title {
        background-color: white;
        padding-left: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
    }

    .content-item-block {
        background-color: #fff;
        padding-left: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 10px;

        .vertical-line {
            border-left: #108ee9 solid 4px;
            padding-left: 10px;
        }
    }





    .student-profile-row{
        background-color: #fff;
        padding-top: 30px;
        .student-profile-content{
            border-radius: 2px;
            margin-top:  40px;
            margin-bottom: 40px;
            padding: 20px;
            border: 1px solid lightgrey;
            min-width:500px ;
            max-width:800px ;
        }
    }



    .content-plain-block{
        background-color: white;

        .plain-radio-group{
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .course-plain-row{
            margin-bottom: 40px;
            display: flex;
            justify-content: left;
            .course-plan-item{
                margin-right: 20px;
                padding: 10px;
                text-align: left;
                width: 400px;
                border-radius: 5px;
                border: 1px #108ee9 solid;
                .plan-item-title{
                    font-size: 18px;
                    font-weight: bold;
                }
                .info-item-desc{
                    font-size: 12px;

                }
            }
        }
    }

    .extends-contract-block{
        .extends-contract-form{
            margin-top: 15px ;
        }
        .extends-contract-item{
            margin: 15px  0;
            width: 28%;
        }
    }


}


</style>
